import { deleteRequest, get, patch, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { ModalSignal, ModalType } from 'components/modals/ModalWrapper'
import { SelectedTubeSignal, getSection, getTask } from 'services'
import { ExportStatus, Matrix, Mission, Paginated, TaskType } from 'types'
import { catchable } from 'utils'

//* --------------- List API URL missions ---------------
export const missionsApi = {
  MISSIONS: '/apt/missions',
  MISSION_EXPORT: (id: string) => `/apt/missions/${id}/publish`,
  MISSION: (id: string) => `/apt/missions/${id}`,
  MATRIX: '/apt/matrix',
  MATRIX_ID: (id: string) => `/apt/matrix/${id}`,
}

//* --------------- Signal to store missions ---------------
export const MissionsSignal = signal<Paginated<Mission>>(null)
export const MissionSignal = signal<Mission>(null)
export const MatrixSignal = signal<Paginated<Matrix>>(null)

//* --------------- Update missions to the signal ---------------
/**
 * Update missions to the signal
 *
 * @param missions - list of missions
 */
export const updateMissions = (missions: Paginated<Mission>) => {
  MissionsSignal.value = missions
}

/**
 * Update Mission to the signal
 * @param mission - mission
*/
export const updateMission = (mission: Mission) => {
  MissionSignal.value = mission
}

/**
 * Fetches a mission by section
 *
 * @param id - mission id
 */
export const getMissionBySection = (id: string) => MissionsSignal.value.results.find(
  mission => mission.missionId === id,
)

/**
 * Update Matrix to the signal
 * @param matrix - matrixPaginate
*/
export const updateMatrix = (matrix: Paginated<Matrix>) => {
  MatrixSignal.value = matrix
}

//* --------------- API missions ---------------
/**
 * Fetches Matrix
 */
export const getMatrix = (url = '') => catchable(async () => {
  const tube = SelectedTubeSignal.value
  if (!tube) return
  if (url) {
    const response = await get<Paginated<Matrix>>(url)
    updateMatrix({
      ...response,
      results: [
        ...MatrixSignal.value?.results || [],
        ...response.results,
      ],
    })
    return
  }
  const response = await get<Paginated<Matrix>>(
    missionsApi.MATRIX,
    {
      tube: tube.libelle,
      page_size: 20,
    },
  )
  updateMatrix(response)
})

/**
 * Fetches a mission by its id
 * @param id - mission id
 * @returns Mission
 */
export const getMissionById = (id: string) => {
  const result = MissionsSignal.value.results.find(mission => mission.missionId === id)
  return result
}

/**
 * Fetches all missions for the selected tube
 */
export const getMissions = (url = '') => catchable(async () => {
  const tube = SelectedTubeSignal.value
  if (!tube) return
  if (url) {
    const response = await get<Paginated<Mission>>(url)
    updateMissions({
      ...response,
      results: [
        ...MissionsSignal.value?.results || [],
        ...response.results,
      ],
    })
    const ids = response.results.map(mission => mission.id) || []
    ids.forEach(id => getSection(id))
    return
  }
  const response = await get<Paginated<Mission>>(
    missionsApi.MISSIONS,
    {
      tube: tube.libelle,
      page_size: 5,
    },
  )
  updateMissions({
    ...response,
    results: [
      ...MissionsSignal.value?.results || [],
      ...response.results,
    ],
  })
  const ids = response.results.map(mission => mission.id) || []
  ids.forEach(id => getSection(id))
})

export const getMission = (id: string) => catchable(async () => {
  MissionSignal.value = null
  const response = await get<Mission>(missionsApi.MISSION(id))
  updateMission(response)
})

/**
 *  Update Mission API
*/
export const patchMission = (mission: Partial<Mission>) => catchable(async () => {
  const response = await patch<Mission>(missionsApi.MISSION(mission.id), mission)
  if (response) {
    ToastSignal.value = {
      message: terms.Pages.Mission.updatedSuccess,
      severity: ToastLevel.SUCCESS,
    }
    updateMission(response)
  }
}, true)

/**
 * Get the status of the task
 * @param id - task id
 */
export const getTaskExportMission = async (
  taskId: string,
  missionId: string,
  exportModalCallback: (type: ModalType) => void,
) => {
  let result = await getTask(taskId) as TaskType
  const interval = setInterval(async () => {
    switch (result.status) {
      case ExportStatus.SUCCESS:
        ModalSignal.value = null
        clearInterval(interval)
        exportModalCallback(ModalType.SUCCESS)
        getMission(missionId)
        break
      case ExportStatus.FAILURE:
        ModalSignal.value = null
        clearInterval(interval)
        exportModalCallback(ModalType.ERROR)
        getMission(missionId)
        break
      case ExportStatus.PENDING:
        result = await getTask(taskId) as TaskType
        break
      default:
        break
    }
  }, 1000)
}

/**
 * Export mission to RUS
*/
export const exportMission = (id: string, exportModalCallback: (type: ModalType) => void) => catchable(async () => {
  ModalSignal.value = null
  const response = await post(missionsApi.MISSION_EXPORT(id), {})

  if (response) {
    exportModalCallback(ModalType.LOADING)
    getTaskExportMission(response.taskId, id, exportModalCallback)
  }
}, true)

/**
 * Create a new mission
 * @param missionId - mission to create
*/
export const postMission = (missionId: string) => catchable(async () => {
  const response = await post<Mission>(missionsApi.MISSIONS, { missionId })
  if (response) {
    ToastSignal.value = {
      message: terms.Pages.Missions.Modal.CreateMission.createSuccess,
      severity: ToastLevel.SUCCESS,
    }
    updateMission(response)
  }
  return response
}, true)

/**
 * Delete a mission
 * @param id - mission id
*/
export const deleteMission = (id: string) => catchable(async () => {
  const response = await deleteRequest(missionsApi.MISSION(id))
  if (response) {
    ToastSignal.value = {
      message: terms.Pages.Mission.Delete.deleteSuccess,
      severity: ToastLevel.SUCCESS,
    }
    if (MatrixSignal.value) {
      MatrixSignal.value.results = MatrixSignal.value.results.filter(matrix => matrix.missionId !== id)
    }
  }
}, true)

//* --------------- Utils Mission ---------------

/**
 * Function to verify pattern of mission id
 *  - 6 characters  [0-9A-Z]
 * @param val string
 * @returns boolean
*/
export const verifyMissionId = (val: string) => {
  if (val.length !== 6) {
    return false
  }
  const pattern = /^[0-9A-Z]{6,}$/
  return pattern.test(val)
}

/**
 * Function to verify pattern of numero sillon
 * val string
 * @returns boolean
*/
export const validFormatNumSillon = (val: string) => {
  const pattern = /^[0-9]+$/
  return val.length <= 10 && pattern.test(val)
}

/**
 * function to update matrix API
*/
export const updateMatrixApi = (matrix: Partial<Matrix>, tronconId: string) => catchable(async () => {
  const response = await patch<Matrix>(missionsApi.MATRIX_ID(tronconId), matrix)
  if (response) {
    ToastSignal.value = {
      message: terms.Pages.Missions.lot.updateSuccess,
      severity: ToastLevel.SUCCESS,
    }
  }
}, true)
