import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Lot } from 'types'
import { SectionLot } from './SectionLot'

interface Props {
  lot: Lot
  onOpenUpdateModal: (id: string, sectionId) => void
  sectionId: string
}
export function SortableLot({ lot, onOpenUpdateModal, sectionId }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: lot.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div className="sortable-lot" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <SectionLot lot={lot} onOpenUpdateModal={onOpenUpdateModal} sectionId={sectionId} />
    </div>
  )
}
